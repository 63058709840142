import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './styles/adress.css';

const AddressInput = ({ value, onSelect }) => {
  const [inputValue, setInputValue] = useState(value || '');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);

    if (newValue.length >= 3) {
      fetchSuggestions(newValue);
    } else {
      setSuggestions([]);
    }
  };

  const fetchSuggestions = async (query) => {
    setLoading(true);

    try {
      const response = await axios.get('https://pretti-207c14282d6e.herokuapp.com/osoite', {
        params: { input: query }
      });

      const formattedSuggestions = response.data.map(suggestion => ({
        label: `${suggestion.structured_formatting.main_text} - ${suggestion.structured_formatting.secondary_text}`,
        value: suggestion.place_id
      }));

      setSuggestions(formattedSuggestions);
    } catch (error) {
      console.error('Error ffetching suggestions:', error);
      setError(error.response ? error.response.status : 'Network error');
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = async (selectedOption) => {
    if (!selectedOption) return;

    setInputValue(selectedOption.label); // Update inputValue immediately
    setSuggestions([]);

    try {
      const placeDetailsResponse = await axios.get('https://pretti-207c14282d6e.herokuapp.com/osoite/save', {
        params: { place_id: selectedOption.value }
      });

      const placeDetails = placeDetailsResponse.data;
      const locationData = {
        location: {
          latitude: placeDetails.location.lat,
          longitude: placeDetails.location.lng,
        },
        postalCode: placeDetails.postalcode?.long_name ?? "",
        formatted: placeDetails.formatted_address,
        city: placeDetails.city
      };

      onSelect(locationData);
    } catch (error) {
      console.error('Error fetching place details:', error);
      setError(error.response ? error.response.status : 'Network error');
    }
  };

  return (
    <div className="address-input-container">
      <Select
        value={suggestions.find(option => option.label === inputValue)} // Ensure the selected value is displayed
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelectChange}
        options={suggestions}
        isLoading={loading}
        isClearable={true} // Add the clear icon
        placeholder="Toimipaikkasi osoite"
        noOptionsMessage={() => (error ? `Error: ${error}` : "No options")}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default AddressInput;
