// src/App.js
import React from 'react';
import './App.css';
import Login from './login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ConsolePage from './ConsolePage';
import EditProfile from './profileEditing/EditProfile';
import Register from './register';
function App() {

  return (
    <Router>
      <div className="App">
        <Routes>
        <Route path="/" element={<ConsolePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/console" element={<ConsolePage />} />
          <Route path="/editProfile" element={<EditProfile />} />
          <Route path= "/register" element={<Register />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
