import React, { useState, useEffect } from 'react';
import './styles/firstPage.css';

const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

// Modal Component
const PrivacyPolicyModal = ({ onAccept, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Tietosuojaseloste</h2>
        <p>
          Tämä on tietosuojaseloste, jossa selitetään, miten käsittelemme henkilötietoja.
          Bela kerää ja säilyttää henkilötiedot ainoastaan profiilin luomista varten. 
          Tietoja ei jaeta kolmansille osapuolille. Klikkaamalla "Hyväksy" hyväksyt seuraavien 
          henkilötietojen näkymisen: ikä (pelkkä vuosi), nimi, sukupuoli sekä profiilikuva. 
          Kaikki tiedot ovat suojattuja, ja voit milloin tahansa pyytää tietojesi poistamista.
        </p>
        <div className="modal-buttons">
          <button className="accept-button" onClick={onAccept}>Hyväksy</button>
          <button className="close-button" onClick={onClose}>Peruuta</button>
        </div>
      </div>
    </div>
  );
};

const FirstPage = ({ onNext }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted the privacy policy
    const token = localStorage.getItem("authToken");

    const checkPrivacyAccepted = async () => {
      try {
        const userData = await getDataFromServer(token);
        if (userData.privacyAccepted) {
          setIsAccepted(true);
        }
      } catch (error) {
        console.error('Error checking privacy acceptance:', error);
      }
    };

    checkPrivacyAccepted();
  }, []);

  const handleNextClick = () => {
    if (isAccepted) {
      onNext();
    } else {
      setIsModalOpen(true); // Open modal if privacy policy not yet accepted
    }
  };

  const handleAccept = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const data = {
        privacyAccepted: true, // Sending true as user accepted the privacy policy
      };

      // Call the function to send data to server
      await postDataToServer(token, data);

      // Update the state to indicate acceptance
      setIsAccepted(true);
      setIsModalOpen(false); // Close modal on accept
      onNext(); // Proceed to the next step
    } catch (error) {
      console.error('Error sending data to server:', error);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false); // Close modal without accepting
  };

  return (
    <div className="first-page-wrapper">
      <p className="intro-text">Täällä täydennät profiilisi, joka näkyy asiakkaille.</p>
      <p className="instructions-header">Muokkausohjeet:</p>
      <ol className="instructions-list">
        <li>Järjestä vaihtoehdot vetämällä ja pudottamalla ne haluttuun järjestykseen.</li>
        <li>Hakualgoritmi antaa teille paljon pisteitä korkealle sijoitetuista kohteista.</li>
        <li>Osassa vaihtoehdoista on syöttökenttä palvelun hinnalle. Tyhjäksi jätetyn syöttökentän palvelua ei löydy hintahausta.</li>
        <li>Antamasi tiedot tallentuvat, kun siirryt seuraavalle tai edelliselle sivulle</li>
      </ol>
      <div className="button-group69">
        <button onClick={handleNextClick}>Seuraava</button>
      </div>

      {/* Render the Privacy Policy modal if open */}
      {isModalOpen && <PrivacyPolicyModal onAccept={handleAccept} onClose={handleClose} />}
    </div>
  );
};

export default FirstPage;
