import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordReset from './PasswordReset'; // Import the PasswordReset component
import logo from './logo.svg';
import './App.css'; // Import general styles
import './login.css'; // Import input styles

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPasswordReset, setShowPasswordReset] = useState(false); // New state to toggle password reset view
  const [message, setMessage] = useState(''); // Define message state here
  const navigate = useNavigate();

  const handleLogin = async () => {
    const url = "https://pretti-207c14282d6e.herokuapp.com/users/login";

    const payload = {
      email: email,
      password: password
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      localStorage.setItem("authToken", data.token); // Using localStorage instead of wix-storage-frontend
   
      navigate('/console'); // Redirect to the /console page
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const goToRegister = () => {
    navigate('/register'); // Redirect to the /register page
  };

  const handleForgotPassword = () => {
    setShowPasswordReset(true); // Show the password reset form
  };

  const handleResetComplete = () => {
    setShowPasswordReset(false); // Hide the password reset form after successful reset
    setMessage('Password reset successful. You can now log in with your new password.');
  };

  return (
    <div className="App">
      {!showPasswordReset ? (
        <div className="login-container">
          <h3>kirjaudu sisään</h3>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Kirjaudu</button>
          <button onClick={goToRegister}>Rekisteröidy</button>
          <button onClick={handleForgotPassword}>Unohditko salasanasi?</button> {/* New button */}
          {message && <p>{message}</p>} {/* Display message if exists */}
        </div>
      ) : (
        <PasswordReset onResetComplete={handleResetComplete} /> // Conditionally render PasswordReset
      )}
    </div>
  );
}

export default Login;
