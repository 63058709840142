import React, { useState } from 'react';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import FifthPage from './FifthPage';
import SixthPage from './SixthPage';
import SeventhPage from './SeventhPage';
import EightPage from './EightPage';
import NinthPage from './NinthPage';
import TenthPage from './TenthPage';
import EleventhPage from './EleventhPage';
import TwelwethPage from './TwelwethPage';
import ThirteenthPage from './ThirteenthPage';
import FourteenthPage from './FourteenthPage';
import FifteenthPage from './FifteenthPage';
import SixteenthPage from './SixteenthPage';
import HairLengthPage from './hiusPituus';
import PaaKategoria from './paaKategoria';
import './styles/MenuPage.css';
import './styles/seventhPage.css';
import Pidennykset from './pidennykset';


const MenuPage = () => {
  const [step, setStep] = useState(1);

  const goToPage = (pageNumber) => {
    setStep(pageNumber);
  };

  const renderPage = () => {
    switch (step) {
      case 1:
        return <FirstPage onNext={() => setStep(2)} />;
      case 2:
        return (
          <div className="menu-wrapper">
            <div className="menu-page">
              <h1>Menu</h1>
              <div className="button-container">
                <button onClick={() => goToPage(3)}>Henkilötiedot</button>
                <button onClick={() => goToPage(4)}>Pääkategoria</button>
                <button onClick={() => goToPage(5)}>Kampaukset</button>
                <button onClick={() => goToPage(6)}>Värjäykset</button>
                <button onClick={() => goToPage(7)}>Leikkaukset</button>
                <button onClick={() => goToPage(8)}>Hoidot</button>
                <button onClick={() => goToPage(9)}>Pidennykset</button>
                <button onClick={() => goToPage(10)}>Hiuspituus</button>
                <button onClick={() => goToPage(11)}>Hiusväri</button>
                <button onClick={() => goToPage(12)}>Värjäysten väri</button>
                <button onClick={() => goToPage(13)}>Hiuslaatu</button>
                <button onClick={() => goToPage(14)}>Hiustyyppi</button>
                <button onClick={() => goToPage(15)}>Hiuskunto</button>
                <button onClick={() => goToPage(16)}>Mielenkiinnot</button>
                <button onClick={() => goToPage(17)}>Kuvaus itsestäsi</button>
                <button onClick={() => goToPage(18)}>Persoonallisuus</button>
                <button onClick={() => goToPage(19)}>Profiilikuva</button>
                <button onClick={() => goToPage(20)}>Portfoliokuvat</button>
              </div>
              <div className="button-group69">
                <button className="prev-btn" onClick={() => goToPage(step - 1)}>Edellinen</button>
                <button className="next-btn" onClick={() => goToPage(step + 1)}>Seuraava</button>
              </div>
            </div>
          </div>
        );
      case 3:
        return <SecondPage onNext={() => setStep(4)} onPrevious={() => setStep(2)} />;
      case 4:
        return <PaaKategoria onNext={() => setStep(5)} onPrevious={() => setStep(3)} />;
      case 5:
        return <ThirdPage onNext={() => setStep(6)} onPrevious={() => setStep(4)} />;
      case 6:
        return <FourthPage onNext={() => setStep(7)} onPrevious={() => setStep(5)} />;
      case 7:
        return <FifthPage onNext={() => setStep(8)} onPrevious={() => setStep(6)} />;
      case 8:
        return <SixthPage onNext={() => setStep(9)} onPrevious={() => setStep(7)} />;
      case 9:
        return <Pidennykset onNext={() => setStep(10)} onPrevious={() => setStep(8)} />;
      case 10:
        return <HairLengthPage onNext={() => setStep(11)} onPrevious={() => setStep(9)} />;
      case 11:
        return <SeventhPage onNext={() => setStep(12)} onPrevious={() => setStep(10)} />;
      case 12:
        return <EightPage onNext={() => setStep(13)} onPrevious={() => setStep(11)} />;
      case 13:
        return <NinthPage onNext={() => setStep(14)} onPrevious={() => setStep(12)} />;
      case 14:
        return <TenthPage onNext={() => setStep(15)} onPrevious={() => setStep(13)} />;
      case 15:
        return <EleventhPage onNext={() => setStep(16)} onPrevious={() => setStep(14)} />;
      case 16:
        return <TwelwethPage onNext={() => setStep(17)} onPrevious={() => setStep(15)} />;
      case 17:
        return <ThirteenthPage onNext={() => setStep(18)} onPrevious={() => setStep(16)} />;
      case 18:
        return <FourteenthPage onNext={() => setStep(19)} onPrevious={() => setStep(17)} />;
      case 19:
        return <FifteenthPage onNext={() => setStep(20)} onPrevious={() => setStep(18)} />;
      case 20:
        return <SixteenthPage onPrevious={() => setStep(19)} />;
      default:
        return <FirstPage onNext={() => setStep(2)} />;
    }
  };

  return (
    <div className="menu-page">
      {renderPage()}
    </div>
  );
};

export default MenuPage;
