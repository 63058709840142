import React, { useState } from 'react';
import './App.css'; // Import general styles
import './register.css'; // Import input styles
import { useNavigate } from 'react-router-dom';

function Register() {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    const url = "https://pretti-207c14282d6e.herokuapp.com/users/register";

    const payload = {
      name: name,
      surname: surname,
      email: email,
      password: password,
      id: "asdfsadasdfasdfasdfasasdfdffsadf123asdf24234"
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      navigate('/login'); // Redirect to the /login page after successful registration
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="App">
      <div className="register-container">
        <h2>Tervetuloa</h2>
        <p className="description">Profiiliin luontiin menee yhteensä noin 10 minuuttia. Kaikki, jotka tekevät profiilinsa loppuun ennen 1.1.2025 saavat vuoden ilmaisen Belan.</p>
        <h3>Rekisteröidy</h3>
        <input
          type="text"
          placeholder="Nimi"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Sukunimi"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        <input
          type="email"
          placeholder="Sähköposti"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Salasana"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Vahvista Salasana"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button onClick={handleRegister}>Rekisteröidy</button>
      </div>
    </div>
  );
}

export default Register;
