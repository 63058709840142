import React, { useState, useEffect } from 'react';
import './styles/secondPage.css';
import AddressInput from './adress';
import LanguageSelector from './languageInput';
import './styles/adress.css';

const SecondPage = ({ onNext, onPrevious }) => {
  const [formData, setFormData] = useState({
    nimi: '',
    SyntymaAika: '',
    Sukupuoli: '',
    Sijainti: '',
    Paikka: '',
    VarausLinkki: '',
    locationData: null,
    initialSijainti: '', // Store initial Sijainti value
    Kielet: [] // Initialize Kielet as an empty array
  });

  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const fetchData = async () => {
      try {
        const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setFormData({
            nimi: data.nimi || '',
            SyntymaAika: data.SyntymaAika ? new Date(data.SyntymaAika).toISOString().split('T')[0] : '',
            Sukupuoli: data.sukupuoli || '',
            Sijainti: data.kokoOsoiteRimpsu || '',
            Paikka: data.kampaamonNimi || '',
            VarausLinkki: data.VarausLinkki || '',
            locationData: data.kokoOsoiteRimpsu || null,
            initialSijainti: data.kokoOsoiteRimpsu || '', // Initialize initialSijainti
            Kielet: data.Kielet || [] ,
            PalveluSukupuoli: data.PalveluSukupuoli,// Initialize Kielet from fetched data
          });
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLanguageChange = (languages) => {
    setFormData({ ...formData, Kielet: languages });
  };

  const handleNextClick = async () => {
    const token = localStorage.getItem("authToken");
    let formattedData = {
      ...formData,
      SyntymaAika: formData.SyntymaAika ? new Date(formData.SyntymaAika).toISOString() : ''
    };

    // Check if the address has changed
    if (formData.Sijainti === formData.initialSijainti) {
      formattedData.Sijainti = '';
      formattedData.locationData = null;
    }

    try {
      const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formattedData)
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        onNext();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePreviousClick = async () => {
    const token = localStorage.getItem("authToken");
    let formattedData = {
      ...formData,
      SyntymaAika: formData.SyntymaAika ? new Date(formData.SyntymaAika).toISOString() : ''
    };

    // Check if the address has changed
    if (formData.Sijainti === formData.initialSijainti) {
      formattedData.Sijainti = '';
      formattedData.locationData = null;
    }

    try {
      const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formattedData)
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        onPrevious();
      } else {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleAddressSelect = (locationData) => {
    setFormData({
      ...formData,
      Sijainti: locationData.formatted.split(',')[0],
      locationData
    });
  };

  return (
    <div className='pissingTHEBLUEAWAY'>
    <div className="container">
      <h1>Yleistä tietoa</h1>
      <h2>Nimesi</h2>
      <input type="text" name="nimi" value={formData.nimi} onChange={handleChange} placeholder="Nimi" />
      <h2>Syntymäpäiväsi</h2>
      <input type="date" name="SyntymaAika" value={formData.SyntymaAika} onChange={handleChange} placeholder="SyntymäAika" />
      <h2>Sukupuolesi</h2>
      <select name="Sukupuoli" value={formData.Sukupuoli} onChange={handleChange}>
        <option value="Mies">Mies</option>
        <option value="Nainen">Nainen</option>
        <option value="Muu">Muu</option> 
      </select>
      <h2>Toimipaikkasi osoite</h2>
      <AddressInput value={formData.Sijainti} onSelect={handleAddressSelect} />
      <h2>Puhumasi kielet</h2>
      <LanguageSelector selectedLanguages={formData.Kielet} onLanguageChange={handleLanguageChange} />
      <h2>Oletko parturi vai kampaaja?</h2>
      <select name="PalveluSukupuoli" value={formData.PalveluSukupuoli} onChange={handleChange}>
        <option value="Parturi">Parturi</option>
        <option value="Kampaaja">Kampaaja</option>
        <option value="Molemmat">Molemmat</option>
      </select>
      <h2>Toimipaikkasi nimi</h2>
      <input type="text" name="Paikka" value={formData.Paikka} onChange={handleChange} placeholder="Paikka" />
      <h2>Linkki käyttämääsi varaussivuun</h2>
      <input type="text" name="VarausLinkki" value={formData.VarausLinkki} onChange={handleChange} placeholder="VarausLinkki" />
      <div className="button-group69">
        <button onClick={handlePreviousClick}>Edellinen</button>
        <button onClick={handleNextClick}>Seuraava</button>
      </div>
    </div>
    </div>
  );
};

export default SecondPage;