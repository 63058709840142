import React, { useState, useEffect } from 'react';
import './styles/thirteenthPage.css';

const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

const ThirteenthPage = ({ token, onNext, onPrevious }) => {
  const [description, setDescription] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      getDataFromServer(token).then(data => {
        if (data && data.LyhytDescription) {
          setDescription(data.LyhytDescription);
        }
      }).catch(error => {
        console.error("Failed to load data:", error);
      });
    }
  }, []);

  const handleDescriptionChange = (event) => {
    const value = event.target.value;
    if (value.length <= 500) {
      setDescription(value);
    }
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      await postDataToServer(token, { LyhytDescription: description });
    }
  };

  return (
    <div className="thirtheenth-page-container">
      <h1>Kerro itsestäsi lyhyesti asiakkaillesi!</h1>
      <h2> (Max 500 merkkiä)</h2>
      <textarea
        value={description}
        onChange={handleDescriptionChange}
        maxLength={500}
        placeholder="Kirjoita lyhyt kuvaus itsestäsi..."
      />
      <div className="button-group69">
      <button onClick={() => { handleSubmit(); onPrevious(); }}>Edellinen</button>
      <button onClick={() => { handleSubmit(); onNext(); }}>Seuraava</button>
      </div>
    </div>
  );
};

export default ThirteenthPage;
