import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './styles/eightPage.css';

const initialItems = [
'Normaalit',
'Rasvoittuvat',
'Hauraat',
];

const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

const EleventhPage = ({ onNext, onPrevious }) => {
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      getDataFromServer(token).then(data => {
        if (data && data.hiusKunto && data.hiusKunto.length > 0) {
          setItems(data.hiusKunto);
        }
      }).catch(error => {
        console.error("Failed to load data:", error);
      });
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setItems(newItems);
  };

  const handleNext = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      await postDataToServer(token, { hiusKunto: items });
      onNext();
    }
  };

  const handlePrevious = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      await postDataToServer(token, { hiusKunto: items });
      onPrevious();
    }
  };

  return (
    <div className="seventh-page-wrapper">
      <div className="seventh-page-container">
        <h1>Minkä kuntoisia hiuksia käsittelet mieluiten?</h1>
        
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sortable1">
              {(provided) => (
                <ul
                  id="sortable1"
                  className="sortable1-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {items.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided) => (
                        <li
                          className="ui-state-default"
                          data-value={item}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <span className="number">{index + 1}.</span>
                          <span className="ui-icon ui-icon-arrowthick-2-n-s"></span>
                          {item}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        <div className="button-group69">
          <button onClick={handlePrevious}>Edellinen</button>
          <button onClick={handleNext}>Seuraava</button>
        </div>
      </div>
    </div>
  );
};

export default EleventhPage;
