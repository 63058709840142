import React, { useState } from 'react';
import FirstPage from './FirstPage';
import MenuPage from './MenuPage';
import SecondPage from './SecondPage';
import PaaKategoria from './paaKategoria';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import FifthPage from './FifthPage';
import SixthPage from './SixthPage';
import SeventhPage from './SeventhPage';
import EightPage from './EightPage';
import NinthPage from './NinthPage';
import TenthPage from './TenthPage';
import EleventhPage from './EleventhPage';
import TwelwethPage from './TwelwethPage';
import ThirteenthPage from './ThirteenthPage';
import FourteenthPage from './FourteenthPage';
import FifteenthPage from './FifteenthPage';
import SixteenthPage from './SixteenthPage';
import HairLengthPage from './hiusPituus';
import Pidennykset from './pidennykset';
import './styles/EditProfile.css';


const EditProfile = ({ onClose }) => {
  const [step, setStep] = useState(2);
  const [token, setToken] = useState(localStorage.getItem("authToken"));

  const handleNextStep = () => {
   
    if (step === 19) {
      
      setStep(2); // Go back to step 2 (MenuPage) if the current step is 18
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  return (
    <div className="edit-profile-content">
      {step === 1 && <FirstPage onNext={handleNextStep}/>}
      {step === 2 && <MenuPage  onNext={handleNextStep} onPrevious={handlePreviousStep} />}
      {step === 3 && <SecondPage  onNext={handleNextStep} onPrevious={handlePreviousStep} />}
      {step === 4 && <PaaKategoria  onNext={handleNextStep} onPrevious={handlePreviousStep} />}
      {step === 5 && <ThirdPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 6 && <FourthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 7 && <FifthPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 8 && <SixthPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 9 && <Pidennykset  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 10 && <HairLengthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 11 && <SeventhPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 12 && <EightPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 13 && <NinthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 14 && <TenthPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 15 && <EleventhPage  onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 16 && <TwelwethPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 17 && <ThirteenthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 18 && <FourteenthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 19 && <FifteenthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
      {step === 20 && <SixteenthPage onPrevious={handlePreviousStep} onNext={handleNextStep} />}
     
    </div>
  );
};

export default EditProfile;