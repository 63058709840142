import React, { useState, useEffect } from 'react';
import './styles/14page.css';

const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

const FourteenthPage = ({ token, onNext, onPrevious }) => {
  const [persoonallisuus, setPersoonallisuus] = useState('');
  const [ekstrovertti, setEkstrovertti] = useState('');
  const [puhelias, setPuhelias] = useState('');

  const persoonallisuusOptions = [
    { label: "Valitse persoonallisuutesi", value: "" },
    "INTJ", "INTP", "INFJ", "INFP", "ENTJ", "ENTP", "ENFJ", "ENFP",
    "ISTJ", "ISTP", "ISFJ", "ISFP", "ESTJ", "ESFJ", "ESTP", "ESFP"
  ];
  
  const ekstroverttiOptions = [
    { label: "Oletko ekstrovertti vai introvertti?", value: "" },
    "Ekstrovertti", "Introvertti"
  ];
  
  const puheliasOptions = [
    { label: "Oletko puhelias vai hiljainen?", value: "" },
    "Puhelias", "Hiljainen"
  ];
  
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      getDataFromServer(token).then(data => {
        if (data.Persoonallisuus) {
          setPersoonallisuus(data.Persoonallisuus);
        }
        if (data.Ekstrovertti) {
          setEkstrovertti(data.Ekstrovertti);
        }
        if (data.Puhelias) {
          setPuhelias(data.Puhelias);
        }
      }).catch(error => {
        console.error("Failed to load data:", error);
      });
    }
  }, []);

  const handleSubmit = async () => {
    const dataToSubmit = {
      Persoonallisuus: persoonallisuus,
      Ekstrovertti: ekstrovertti,
      Puhelias: puhelias
    };
    const token = localStorage.getItem("authToken");

    if (token) {
      await postDataToServer(token, dataToSubmit);
    }
  };

  return (
    <div className="fourteenth-page-wrapper">
      <h1>Persoonallisuus</h1>
      <div className="select-wrapper">
        <select value={persoonallisuus} onChange={(e) => setPersoonallisuus(e.target.value)}>
          {persoonallisuusOptions.map((option, index) =>
            typeof option === 'string' ? (
              <option key={index} value={option}>{option}</option>
            ) : (
              <option key={index} value={option.value} disabled={option.value === ""}>
                {option.label}
              </option>
            )
          )}
        </select>
  
        <select value={ekstrovertti} onChange={(e) => setEkstrovertti(e.target.value)}>
          {ekstroverttiOptions.map((option, index) =>
            typeof option === 'string' ? (
              <option key={index} value={option}>{option}</option>
            ) : (
              <option key={index} value={option.value} disabled={option.value === ""}>
                {option.label}
              </option>
            )
          )}
        </select>
  
        <select value={puhelias} onChange={(e) => setPuhelias(e.target.value)}>
          {puheliasOptions.map((option, index) =>
            typeof option === 'string' ? (
              <option key={index} value={option}>{option}</option>
            ) : (
              <option key={index} value={option.value} disabled={option.value === ""}>
                {option.label}
              </option>
            )
          )}
        </select>
  
        <div className="button-group69">
          <button onClick={() => { handleSubmit(); onPrevious(); }}>Edellinen</button>
          <button onClick={() => { handleSubmit(); onNext(); }}>Seuraava</button>
        </div>
      </div>
    </div>
  );
};

export default FourteenthPage;
