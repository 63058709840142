import React, { useState } from 'react';
import axios from 'axios';

const PasswordReset = ({ onResetComplete }) => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [resetCode, setResetCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://pretti-207c14282d6e.herokuapp.com/users/send-reset-code', { email });
            setMessage(response.data);
            setStep(2);
        } catch (error) {
            setMessage(error.response.data || 'Error sending reset code.');
        }
    };

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://pretti-207c14282d6e.herokuapp.com/users/verify-reset-code', { email, resetCode });
            setMessage('Code verified. You may now reset your password.');
            setStep(3);
        } catch (error) {
            setMessage('Invalid or expired code.');
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('https://pretti-207c14282d6e.herokuapp.com/users/reset-password', { email, resetCode, newPassword });
            setMessage('Password has been reset successfully.');
            setStep(4);
            onResetComplete(); // Notify parent component
        } catch (error) {
            setMessage('Failed to reset password.');
        }
    };

    return (
        <div>
            <h2>Reset Your Password</h2>
            {step === 1 && (
                <form onSubmit={handleEmailSubmit}>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Send Reset Code</button>
                </form>
            )}

            {step === 2 && (
                <form onSubmit={handleCodeSubmit}>
                    <label>Reset Code:</label>
                    <input
                        type="text"
                        value={resetCode}
                        onChange={(e) => setResetCode(e.target.value)}
                        required
                    />
                    <button type="submit">Verify Code</button>
                </form>
            )}

            {step === 3 && (
                <form onSubmit={handlePasswordReset}>
                    <label>New Password:</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                    <label>Confirm Password:</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Reset Password</button>
                </form>
            )}

            {message && <p>{message}</p>}
        </div>
    );
};

export default PasswordReset;
