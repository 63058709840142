import React from 'react';
import Select from 'react-select';
import './styles/kielet.css';

const languages = [
  { value: "suomi", label: "Suomi" },
  { value: "ruotsi", label: "Ruotsi" },
  { value: "venäjä", label: "Venäjä" },
  { value: "viro", label: "Viro" },
  { value: "arabia", label: "Arabia" },
  { value: "englanti", label: "Englanti" },
  { value: "somali", label: "Somali" },
  { value: "kurdi", label: "Kurdi" },
  { value: "kiina", label: "Kiina" },
  { value: "albania", label: "Albania" },
  { value: "persia", label: "Persia" },
  { value: "serbokroatia", label: "Serbokroatia" },
  { value: "turkki", label: "Turkki" },
  { value: "thai", label: "Thai" },
  { value: "espanja", label: "Espanja" },
  { value: "saksa", label: "Saksa" },
  { value: "ranska", label: "Ranska" },
  { value: "vietnam", label: "Vietnam" },
  { value: "nepali", label: "Nepali" },
  { value: "urdu", label: "Urdu" }
];

const LanguageSelector = ({ selectedLanguages, onLanguageChange }) => {
  const handleChange = (selectedOptions) => {
    onLanguageChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#333', // Change placeholder text color to black
    }),
  };

  return (
    <div className="language-selector">
      <Select
        value={languages.filter(language => selectedLanguages.includes(language.value))}
        onChange={handleChange}
        options={languages}
        isMulti
        placeholder="Valitse kielet" 
        styles={customStyles}
        classNamePrefix="select"
      />
    </div>
  );
};

export default LanguageSelector;
