import React, { useState, useEffect, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import axios from 'axios';
import './styles/sixteen.css';

const getImagesFromServer = async (token) => {
  const response = await axios.get('https://pretti-207c14282d6e.herokuapp.com/kampaaja/muutKuvat', {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data.urls || [];
};

const uploadImageToServer = async (token, imageData, containerId) => {
  const data = { image: imageData, containerId };
  const response = await axios.post(`https://pretti-207c14282d6e.herokuapp.com/kampaaja/kuvat/${containerId}`, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};


const deleteImageFromServer = async (token, containerId) => {
  const response = await axios.delete(`https://pretti-207c14282d6e.herokuapp.com/kampaaja/deleteImage/muutKuvat/${containerId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data;
};

const SixteenthPage = ({ onNext, onPrevious }) => {
  const [images, setImages] = useState(Array(9).fill(null));
  const [currentImage, setCurrentImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1); // Increased from 1 to 1.5

  const [croppedArea, setCroppedArea] = useState(null);
  const [currentContainerId, setCurrentContainerId] = useState(null);
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    const token = localStorage.getItem("authToken");

    const fetchImages = async () => {
      const urls = await getImagesFromServer(token);
      const newImages = Array(9).fill(null); // Ensure the array is of fixed length
      urls.forEach(({ url, position }) => {
        newImages[position] = url;
      });
      setImages(newImages);
    };
    fetchImages();
  }, [] );

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const getCroppedImg = (imageSrc, croppedArea) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve) => {
      image.onload = () => {
        const ctx = canvas.getContext('2d');
        canvas.width = croppedArea.width;
        canvas.height = croppedArea.height;
        ctx.drawImage(
          image,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          croppedArea.width,
          croppedArea.height
        );
        resolve(canvas.toDataURL('image/jpeg'));
      };
    });
  };

  const handleFileChange = async (e, containerId) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setCurrentImage(reader.result);
      setCurrentContainerId(containerId);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    const croppedImageUrl = await getCroppedImg(currentImage, croppedArea);
    await uploadImageToServer(token, croppedImageUrl, currentContainerId + 1); // Adjusting for MongoDB's 0-based index
    const updatedImages = await getImagesFromServer(token);
    const newImages = Array(9).fill(null);
    updatedImages.forEach(({ url, position }) => {
      newImages[position] = url;
    });
    setImages(newImages);
    setCurrentImage(null);
    setCurrentContainerId(null);
  };

  const handleDelete = async (index) => {
    await deleteImageFromServer(token, index + 1); // Adjusting for MongoDB's 0-based index
    setImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = null;
      return newImages;
    });
  };

  return (
    <div className="sixteenth-page-wrap">
      <div className="container">
        <h2>Valitse portfoliosi kuvat</h2>
        {currentImage && (
          <div className="crop-section">
            <div className="crop-container">
              <Cropper
              image={currentImage}
              crop={crop}
              zoom={zoom}
              aspect={1}  /* Square cropper */
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              />
            </div>
            <button className="upload-button" onClick={handleUpload}>Upload</button>
          </div>
        )}
        <div className="grid">
          {images.map((image, index) => (
            <div key={index} className="image-container">
              {image ? (
                <>
                  <img src={image} alt={`Image ${index + 1}`} className="image" />
                  <div className="delete-icon" onClick={() => handleDelete(index)}>✖</div>
                </>
              ) : (
                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, index)} />
              )}
            </div>
          ))}
        </div>
        <div className="button-group69">
          <button onClick={onPrevious}>Edellinen</button>
        </div>
      </div>
    </div>
  );
  
};

export default SixteenthPage;
