import React, { useState, useEffect, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import 'react-easy-crop/react-easy-crop.css';
import './styles/fifteenthPage.css';

const getDataFromServer = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data from server:', error);
    throw error;
  }
};

const getProfileImage = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/Profiilikuva", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.url;
  } catch (error) {
    console.error('Error fetching profile image:', error);
    throw error;
  }
};

const deleteProfileImage = async (token) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/deleteProfileImage", {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.message;
  } catch (error) {
    console.error('Error deleting profile image:', error);
    throw error;
  }
};

const postDataToServer = async (token, data) => {
  try {
    const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/uploadImage/profile", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
  } catch (error) {
    console.error("Failed to post data:", error);
  }
};

const FifteenthPage = ({onNext, onPrevious }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [existingImage, setExistingImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const token = localStorage.getItem("authToken");
  useEffect(() => {
    
    if (token) {
      getDataFromServer(token).then(data => {
        if (data.profiiliKuva) {
          setImageSrc(data.profiiliKuva);
          setExistingImage(data.profiiliKuva);
        }
      }).catch(error => {
        console.error("Failed to load data:", error);
      });

      getProfileImage(token).then(url => {
        setExistingImage(url);
      }).catch(error => {
        console.error("Failed to load profile image:", error);
      });
    }
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const getCroppedImg = (imageSrc, croppedArea) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = imageSrc;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const ctx = canvas.getContext('2d');
        canvas.width = croppedArea.width;
        canvas.height = croppedArea.height;
        ctx.drawImage(
          image,
          croppedArea.x,
          croppedArea.y,
          croppedArea.width,
          croppedArea.height,
          0,
          0,
          croppedArea.width,
          croppedArea.height
        );
        resolve(canvas.toDataURL('image/jpeg'));
      };

      image.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleUpload = async () => {
    try {
     
      if (token && croppedArea) {
        const croppedImageUrl = await getCroppedImg(imageSrc, croppedArea);
        await postDataToServer(token, { profiiliKuva: croppedImageUrl });
        setImageSrc(null);
        setExistingImage(croppedImageUrl); // Update the existing image after upload
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleDelete = async () => {
    if (token) {
      await deleteProfileImage(token);
      setExistingImage(null);
      setImageSrc(null);
    }
  };

  return (
    <div className="fifteenth-page-wrap">
      <div className="container">
        <h2>Valitse profiilikuva</h2>
        {existingImage && (
          <div className="existing-profile-section">
            <h3>Existing Profile Picture:</h3>
            <img src={existingImage} alt="Existing profile" className="existing-profile-img" />
            <button className="delete-button" onClick={handleDelete}>Delete Profile Picture</button>
          </div>
        )}
        <input type="file" accept="image/*" onChange={handleFileChange} />
        {imageSrc && (
          <div className="crop-section">
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={2 / 2}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <button className="upload-button" onClick={handleUpload}>Upload</button>
          </div>
        )}
        <div className="button-group69">
          <button onClick={onPrevious}>Edellinen</button>
          <button onClick={onNext}>Seuraava</button>
        </div>
      </div>
    </div>
  );
};

export default FifteenthPage;
