import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useNavigate } from 'react-router-dom';
import './ConsolePage.css';
import silhouette from './photos/silhouette.png';
import EditProfile from './profileEditing/EditProfile';
import AnimatedBubble from './businessman';
import AddressInput from './profileEditing/adress';
import CookieConsent from 'react-cookie-consent'; // Import the CookieConsent component

const ConsolePage = () => {
  const navigate = useNavigate();
  const [showProfile, setShowProfile] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showBubble, setShowBubble] = useState(true); // State to manage the visibility of AnimatedBubble
  const [monthOffset, setMonthOffset] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [visibleBoxes, setVisibleBoxes] = useState([]);
  const [profileId, setProfileId] = useState("");
  const [token, setToken] = useState(localStorage.getItem("authToken"));
  const [showAnalytics, setShowAnalytics] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch("https://pretti-207c14282d6e.herokuapp.com/kampaaja/editorinfo", {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setProfileId(data.publicId);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    setIsLoaded(true);
    const boxOrder = [
      'header', 'buttons', 'analytics-section', 'total-clicks', 'tips-slot', 'payments-slot'
    ];
    boxOrder.forEach((box, index) => {
      setTimeout(() => {
        setVisibleBoxes((prev) => [...prev, box]);
      }, index * 500);
    });
  }, [token, navigate]);

  const handleViewProfile = () => {
    setShowProfile(true);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  const handleEditProfile = () => {
    setShowBubble(false); // Hide the bubble when editing profile
    setShowEditProfile(true);
  };

  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
    setTimeout(() => setShowBubble(true), 300); // Show the bubble back with a delay for smooth transition
  };

  const handlePreviousMonth = () => {
    setMonthOffset(monthOffset - 1);
  };

  const handleNextMonth = () => {
    setMonthOffset(monthOffset + 1);
  };

  const handleToggleAnalytics = () => {
    setShowAnalytics(!showAnalytics);
  };

  const generateClickData = (monthOffset) => {
    const baseDate = new Date();
    baseDate.setMonth(baseDate.getMonth() + monthOffset);
    const year = baseDate.getFullYear();
    const month = String(baseDate.getMonth() + 1).padStart(2, '0');
    const daysInMonth = new Date(year, baseDate.getMonth() + 1, 0).getDate();

    const dates = Array.from({ length: daysInMonth }, (v, i) => `${year}-${month}-${String(i + 1).padStart(2, '0')}`);
    const clicks = Array.from({ length: daysInMonth }, () => Math.floor(Math.random() * 10) + 5);

    return { dates, clicks };
  };

  const { dates, clicks } = generateClickData(monthOffset);
  const totalClicks = clicks.reduce((sum, click) => sum + click, 0);

  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Clicks',
        data: clicks,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className={`console-page ${isLoaded ? 'loaded' : ''}`}>
         <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="userConsentCookie"
        style={{ background: "#222" }}
        buttonStyle={{ color: "#4e503b", fontSize: "16px" }}
        expires={0.0001}
      >
Tämä sivusto käyttää evästeitä parantaakseen käyttökokemustasi. Hyväksymällä evästeet hyväksyt evästekäytäntömme.      </CookieConsent>
      <div className="main-content">
        <div className={`animated-bubble-wrapper ${showBubble ? 'visible' : 'hidden'}`}>
          <AnimatedBubble />
        </div>
        <div className={`header ${visibleBoxes.includes('header') ? 'visible' : ''}`}>
          <h1>Bela-Ammattilaisille</h1>
        </div>
        <div className={`buttons ${visibleBoxes.includes('buttons') ? 'visible' : ''}`}>
          <button onClick={handleEditProfile}>Muokkaa profiiliasi</button>
          <button onClick={handleViewProfile}>Katsele profiiliasi</button>
        </div>
        <div className={`tips-slot ${visibleBoxes.includes('tips-slot') ? 'visible' : ''}`}>
          <h3>Ohjeita</h3>
          <ul>
            <li>Täällä pääset luomaan ja katsomaan profiiliasi.</li>
            <li>Muista pitää tiedot ajantasalla sekä tehdä profiili huolellisesti, se parantaa tuloksia.</li>
            <li>Kysy meiltä tarvittaessa apua profiilin luomisessa, autamme mielellämme</li>
            <li>Sähköpostimme on: bela.tiimi@gmail.com</li>
          </ul>
        </div>
        <div className={`payments-slot ${visibleBoxes.includes('payments-slot') ? 'visible' : ''}`}>
          <h3>Maksut</h3>
          <p>Tulossa...</p>
        </div>
      </div>
      <div className="side-page">
        <div 
          className={`total-clicks ${visibleBoxes.includes('total-clicks') ? 'visible' : ''}`} 
          onClick={handleToggleAnalytics}
        >
          <span role="img" aria-label="clicks">📈</span> Kuukauden klikit (tulossa pian): {totalClicks}
        </div>
        {showAnalytics && (
          <div className={`analytics-section ${visibleBoxes.includes('analytics-section') ? 'visible' : ''}`}>
            <div className="graphics-slot">
              <h3>This Month's Clicks</h3>
              <Bar data={data} options={options} />
              <div className="month-buttons">
                <button onClick={handlePreviousMonth}>Previous Month</button>
                <button onClick={handleNextMonth}>Seuraava</button>
              </div>
            </div>
            <div className="text-slot">
              <p>Luo profiilisi huolella ja katso kuinka lukusi kasvavat</p>
            </div>
          </div>
        )}
      </div>
      {showProfile && (
       <div className="profile-popup open">
       <div className="profile-content">
       <div style={{ 
    transform: 'translate(-50%, -50%) scale(1)', 
    width: '80vw', 
    height: '80vh', 
    position: 'absolute',
    top: '50%', 
    left: '50%', 
    margin: 0 // Ensure no margin is applied
}}> 
    <iframe
        src={`https://www.bela.fi/showprofile/${profileId}`}
        title="Profile"
        width="100%"
        height="100%"
        style={{ margin: 0 }} // Ensure no margin is applied
    ></iframe>
    <button onClick={handleCloseProfile}>Sulje</button>
</div>
       </div>
     </div>
     
      )}
      {showEditProfile && (
        <div className="profile-popup open">
          <EditProfile onClose={handleCloseEditProfile} />
          <button class="profile-popup__close" onClick={handleCloseEditProfile}>×</button>
        </div>
      )}
    </div>
  );
};

export default ConsolePage;